<template>
  <S2SForm title="Options">
    <S2SCard
      title="Add Option set"
      toolbar-class="elevation-1"
      color="white"
      toolbarColor="white"
      :containerProps="{ 'elevation-1': true }"
    >
      <v-form ref="form" lazy-validation>
        <v-layout row wrap>
          <v-flex xs12 sm6 px-2>
            <v-text-field
              :rules="[(v) => !!v || 'Name is required']"
              v-model="option['name']"
              label="Option Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 px-2>
            <v-text-field
              :rules="[(v) => !!v || 'Description is required']"
              v-model="option['description']"
              label="Description"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-flex class="text-right">
          <v-btn color="accent" @click="onOptionAdd">Add</v-btn>
        </v-flex>
      </v-form>
    </S2SCard>
    <br />

    <S2SCard title="Option sets">
      <template>
        <v-row>
          <v-col cols="6"
            ><v-checkbox
              label="Show Disabled"
              v-model="showDisabled"
              @change="onShowDisabled($event)"
            ></v-checkbox
          ></v-col>
          <v-col cols="6"
            ><v-text-field
              v-model="searchVal"
              label="Filter Option Set"
              clearable
            ></v-text-field
          ></v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="options"
          single-expand
          show-expand
          :search="searchVal"
        >
          <template v-slot:item.displayName="props">
            <v-edit-dialog
              :return-value.sync="props.item.displayName"
              @save="save(props.item, 'optionSet')"
              @cancel="cancel"
              @open="open"
              @close="close"
            >
              {{ props.item.displayName }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.displayName"
                  :rules="[
                    (v) => v.length <= 25 || 'Input too long!',
                    (v) => !!v || 'Name field is required!',
                  ]"
                  label="Name"
                  single-line
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.description="props">
            <v-edit-dialog
              :return-value.sync="props.item.description"
              @save="save(props.item, 'optionSet')"
              @cancel="cancel"
              @open="open"
              @close="close"
            >
              {{ props.item.description }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.description"
                  :rules="[(v) => !!v || 'Name field is required!']"
                  label="Description"
                  single-line
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <!-- expand column -->
          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-btn @click="expand(true)" v-show="!isExpanded" icon>
              <v-icon>mdi-eye</v-icon>
              <v-badge
                overlap
                :content="item.options.length ? item.options.length : '0'"
                color="white"
              ></v-badge>
            </v-btn>
            <v-btn @click="expand(false)" v-show="isExpanded" icon dark>
              <v-icon>mdi-eye-off</v-icon>
              <v-badge
                overlap
                :content="item.options.length ? item.options.length : '0'"
                color="white"
              ></v-badge>
            </v-btn>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="accent"
                  @click="
                    dialog = true;
                    optionId = item.id;
                  "
                  v-on="on"
                >
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>Add option</span>
            </v-tooltip>
          </template>

          <!-- expand item/row -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-2">
              <v-data-table
                :headers="[
                  { text: 'Name', value: 'name' },
                  { text: 'Price', value: 'price' },
                  { text: 'SKU', value: 'sku' },
                  {
                    text: 'Active',
                    value: 'active',
                    sortable: false,
                    width: '80px',
                  },
                ]"
                :items="item.options"
                hide-default-footer
                :mobile-breakpoint="0"
              >
                <template v-slot:item.name="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="save(props.item, 'optionSetItem')"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ props.item.name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.name"
                        :rules="[(v) => !!v || 'Name field is required!']"
                        label="name"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.price="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.price"
                    @save="save(props.item, 'optionSetItem')"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ formatCurrency(props.item.price) }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.price"
                        :rules="[
                          (v) => !!v || 'Price field is required!',
                          (v) =>
                            /^\d+(\.)?\d+$/.test(v) ||
                            'Price format incorrect.',
                        ]"
                        label="price"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.sku="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.sku"
                    @save="save(props.item, 'optionSetItem')"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ props.item.sku }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.sku"
                        :rules="[(v) => !!v || 'SKU field is required!']"
                        label="sku"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.active="{ item }">
                  <v-btn
                    icon
                    @click="disableOptionItem({ item, showDisabled })"
                  >
                    <v-icon color="accent" v-if="item.enabled" medium
                      >mdi-checkbox-marked</v-icon
                    >
                    <v-icon v-else-if="!item.enabled" medium
                      >mdi-close-box</v-icon
                    >
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.active="{ item }">
            <v-btn
              icon
              @click="disableOption({ item, showDisabled })"
              class="parent"
            >
              <v-icon color="accent" v-if="item.enabled" medium
                >mdi-checkbox-marked</v-icon
              >
              <v-icon v-else-if="!item.enabled" medium>mdi-close-box</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </template>
    </S2SCard>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Create new option
        </v-card-title>

        <v-form ref="optionForm" class="pa-5">
          <v-layout row wrap>
            <v-flex xs12 sm6 px-2>
              <v-text-field
                label="Name"
                :name="`optionItemName-${optionId}`"
                :rules="[(v) => !!v || 'Name is required']"
                v-model="optionSetItemModel[`optionItemName-${optionId}`]"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-2>
              <v-text-field
                label="Cost"
                v-money="currencyOptions"
                v-model="optionSetItemModel[`optionItemPrice-${optionId}`]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-2>
              <v-text-field
                label="SKU"
                :name="`optionItemSKU-${optionId}`"
                v-model="optionSetItemModel[`optionItemSKU-${optionId}`]"
                messages="optional"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="onCancel(optionId)">Cancel</v-btn>
            <v-btn color="accent" @click="onOptionItemAdd(optionId)"
              >Create</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";

  const VMoney = require("v-money").VMoney;

  export default Vue.extend({
    name: "Options",

    directives: { money: VMoney },

    data: function () {
      return {
        optionSetItemModel: {},

        option: { name: "", displayName: "", description: "" },
        headers: [
          { text: "Name", value: "displayName" },
          { text: "Description", value: "description" },
          {
            text: "Options",
            value: "data-table-expand",
            align: "center",
            sortable: false,
            width: "110px",
          },
          {
            text: "Active",
            value: "active",
            align: "center",
            sortable: false,
            width: "80px",
          },
        ],

        dialog: false,
        optionId: 0,
        updateSuccess: true,

        snack: false,
        snackColor: "",
        snackText: "",

        showDisabled: false,
        searchVal: "",

        /* Currency Input Settings */
        currencyOptions: {
          decimal: ".",
          thousands: " ",
          prefix: "R ",
          precision: 2,
          masked: false,
        },
      };
    },

    computed: {
      options: function () {
        return this.$store.state.options.options;
      },
      pagination: function () {
        return this.$store.state.options.pagination;
      },
      loading: function () {
        return this.$store.state.options.loading;
      },
    },

    methods: {
      getValidationDict() {
        return {
          attributes: {
            optionItemName: "Option Item Name",
          },
        };
      },
      onOptionAdd() {
        if (this.$refs.form.validate()) {
          this.option.displayName = this.option.name;
          this.$store.dispatch("options/createOption", this.option);
        }
        // clear form
        this.option = { name: "", displayName: "", description: "" };
        this.$refs.form.reset();
      },
      onOptionItemAdd(itemId) {
        if (this.$refs.optionForm.validate()) {
          const optionItemObj = {
            name: this.optionSetItemModel[`optionItemName-${itemId}`],
            price: +this.optionSetItemModel[
              `optionItemPrice-${itemId}`
            ].replace(/[a-z]/gi, ""),
            sku: this.optionSetItemModel[`optionItemSKU-${itemId}`],
            setId: itemId,
          };

          this.$store.dispatch("options/createOptionItem", optionItemObj);

          this.$refs.optionForm.reset();

          this.dialog = false;
        }
      },
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
      onCancel(itemId) {
        // v-money clear workaround
        this.$refs.optionForm.$el.getElementsByTagName("input")[1].value = 0;

        this.$refs.optionForm.reset();
        this.dialog = false;
      },
      onShowDisabled(isDisabled) {
        this.$store.dispatch("options/fetchOptions", isDisabled);
      },
      open() {},
      save(option, field) {
        if (option.name == "" || option.description == "") {
          this.updateSuccess = false;
          return;
        }
        switch (field) {
          case "optionSet":
            option.name = option.displayName;
            this.$store.dispatch("options/updateOption", {
              optionData: {
                name: option.name,
                displayName: option.displayName,
                description: option.description,
              },
              id: option.id,
              showDisabled: this.showDisabled,
            });

            break;
          case "optionSetItem":
            this.$store.dispatch("options/updateOptionItem", {
              optionItemData: {
                name: option.name,
                price: option.price,
                sku: option.sku,
              },
              id: option.id,
              showDisabled: this.showDisabled,
            });
            break;
        }
      },
      cancel() {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },
      close() {
        if (!this.updateSuccess) {
          this.$store.dispatch("options/fetchOptions", this.showDisabled);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Data could not be saved";
          this.updateSuccess = !this.updateSuccess;
        }
      },
    },

    mounted: function () {
      this.$store.dispatch("options/fetchOptions", this.showDisabled);
    },
  });
</script>

<style>
  .v-datatable__expand-row {
    background-color: #efefef !important;
  }

  tr.v-data-table__expanded,
  tr.v-data-table__expanded .v-btn.icon {
    color: #fff;
    background: #0a2645 !important;
  }

  tr.v-data-table__expanded .v-btn.parent .v-icon {
    color: #fff;
  }

  .v-badge__badge {
    border-radius: 50%;
    padding: 0;
    width: 13px;
    min-width: 13px;
    height: 13px;
    font-size: 10px;
    line-height: 130%;
    position: absolute;
    left: -35px !important;
    bottom: -15px !important;
    color: #777;
  }
</style>
